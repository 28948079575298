<template>
  <div class="text-center">
    <b-button-group size="sm">
      <b-btn :to="`/wallets/${wallet}`" variant="dark" :class="selected('home')">Wallet Home</b-btn>
      <b-btn :to="`/wallets/${wallet}/predict`" variant="dark" :class="selected('predict')">ทายผล</b-btn>
      <b-btn :to="`/wallets/${wallet}/fixtures`" variant="dark" :class="selected('fixtures')">ตารางแข่ง</b-btn>
      <b-btn :to="`/wallets/${wallet}/leaderboard`" variant="dark" :class="selected('leaderboard')">อันดับ</b-btn>
      <b-btn :to="`/wallets/${wallet}/rewards`" variant="dark" :class="selected('rewards')">ของรางวัล</b-btn>
    </b-button-group>
  </div>
</template>

<script>
export default {
  props: ['wallet'],
  methods: {
    selected (type) {
      return this.type === type ? 'selected' : ''
    }
  },
  computed: {
    type () {
      return this.$route.meta.type || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.selected {
  background-color: #71aaff !important;
}
</style>
