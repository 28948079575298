<template>
  <div>
    <!-- Menu -->
    <Menu :wallet="wallet" class="mb-8 d-lg-none" />
    <div class="row">
      <!-- 1st place -->
      <div class="col-sm-6 col-md-4">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-body p-0">
            <div class="card-rounded p-8 bgi-no-repeat rank rank1">
              <b-row align-h="between">
                <b-col cols="auto" class="h3">อันดับ 1</b-col>
                <b-col cols="auto" class="h3">{{ rank1.name }}</b-col>
              </b-row>
              <b-row align-h="between" align-v="center" class="mt-8">
                <div class="symbol symbol-label symbol-65 pl-4">
                  <img v-if="rank1.img" :src="rank1.img" class="symbol-label w-100 h-100" />
                  <div v-else class="w-100 h-100" />
                </div>
                <div class="font-size-h3 font-weight-bolder display-4 text-right">{{ rank1.pts }}</div>
              </b-row>
            </div>
          </div>
        </div>
      </div>
      <!-- 2nd place -->
      <div class="col-sm-6 col-md-4">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-body p-0">
            <div class="card-rounded p-8 bgi-no-repeat rank rank2">
              <b-row align-h="between">
                <b-col cols="auto" class="h3">อันดับ 2</b-col>
                <b-col cols="auto" class="h3">{{ rank2.name }}</b-col>
              </b-row>
              <b-row align-h="between" align-v="center" class="mt-8">
                <div class="symbol symbol-label symbol-65 pl-4">
                  <img v-if="rank2.img" :src="rank2.img" class="symbol-label w-100 h-100" />
                  <div v-else class="w-100 h-100" />
                </div>
                <div class="font-size-h3 font-weight-bolder display-4 text-right">{{ rank2.pts }}</div>
              </b-row>
            </div>
          </div>
        </div>
      </div>
      <!-- 3rd place -->
      <div class="col-sm-6 col-md-4">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-body p-0">
            <div class="card-rounded p-8 bgi-no-repeat rank rank3">
              <b-row align-h="between">
                <b-col cols="auto" class="h3">อันดับ 3</b-col>
                <b-col cols="auto" class="h3">{{ rank3.name }}</b-col>
              </b-row>
              <b-row align-h="between" align-v="center" class="mt-8">
                <div class="symbol symbol-label symbol-65 pl-4">
                  <img v-if="rank3.img" :src="rank3.img" class="symbol-label w-100 h-100" />
                  <div v-else class="w-100 h-100" />
                </div>
                <div class="font-size-h3 font-weight-bolder display-4 text-right">{{ rank3.pts }}</div>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Leaderboard -->
    <div class="card card-custom gutter-b card-stretch">
      <div class="card-header border-0 py-5">
        <div class="card-title align-items-start flex-column">
          <span class="font-size-h3 card-label text-white font-weight-bolder">Leaderboard</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">Hall of fame from {{ members }} active members</span>
        </div>
        <div class="text-right">
          <div class="text-muted mt-1 font-size-sm">Latest update: {{ latest }}</div>
        </div>
      </div>
      <div class="card-body pt-2">
        <b-row v-for="r in users" v-bind:key="r.rank" align-v="center" class="mb-4">
          <b-col cols="auto">
            <div class="symbol symbol-40 symbol-light">
              <div class="symbol-label">
                {{ r.rank }}
              </div>
            </div>
          </b-col>
          <b-col class="font-weight-bolder">
            {{ r.name }}
          </b-col>
          <b-col sm="*" :xs="10" class="font-weight-bolder">
            {{ r.pts }} คะแนน
          </b-col>
          <b-col :sm="2" xs="*" class="text-right">
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Menu from "./Menu";

export default {
  components: {
    Menu
  },
  data() {
    return {
      tops: [],
      users: [],
      members: 0,
      time: 0
    }
  },
  methods: {
    recvData() {
      this.axios.get(`/wallets/${this.wallet}/leaderboard`).then(
        ({ data }) => {
          this.tops = data.tops || []
          this.users = data.users || []
          this.members = data.members || 0
          this.time = data.lastupdate || 0
        }
      )
    },
    df (p) {
      return p.diff > 0 ? `+${p.diff}%` : `-${p.diff}%`
    }
  },
  computed: {
    rank1() {
      return this.tops[0] || {}
    },
    rank2() {
      return this.tops[1] || {}
    },
    rank3() {
      return this.tops[2] || {}
    },
    tour() {
      return this.$route.params.tour
    },
    game() {
      return this.$route.params.game
    },
    wallet() {
      return this.$route.params.wallet
    },
    latest() {
      return this.time != null && this.time > 0 ? dayjs(this.time).format('DD/MM/YYYY HH:mm') : '-'
    }
  },
  mounted() {
    this.recvData()
  }
}
</script>

<style lang="scss" scoped>
.rank {
  background-position: top center;
  background-size: cover;
  height: 160px;

  &.rank1 {
    background-image: url('/images/rank1_1.png')
  }

  &.rank2 {
    background-image: url('/images/rank2_1.png')
  }

  &.rank3 {
    background-image: url('/images/rank3_1.png')
  }
}
</style>
